<template>
    <div>
        <v-toolbar class="sub-bar">
            <v-toolbar-title>
                <v-icon>fa fa-history</v-icon> Your Recent Activity
            </v-toolbar-title>
        </v-toolbar>
        <v-row class="sub-cont">
            <v-col cols="3" class="hidden-sm-and-down pr-0">
                <LeftNav />
            </v-col>
            <v-col cols="12" md="9">
                <v-row no-gutters>
                    <v-col cols="12" class="pb-3">
                        <v-card class="elevation-1 fill-height bg-wavelines-secondary-dark" color="secondary" dark>
                            <v-card-text class="px-3 pb-4 pt-3 pt-lg-3 text-center fill-height d-flex align-center justify-center">
                                <div style="width: 100%;">
                                    <p class="text-center white--text settings-title subtitle-1 font-weight-medium mt-0 mb-0">
                                        Check-in Stats
                                    </p>
                                    <v-row dense class="align-center">
                                        <v-col cols="3" sm="4" order="1" order-sm="1">
                                            <div class="stat-item">
                                                <span class="title text-md-h4 success--text">{{ user.total_checkins || '0' }}</span>
                                                <br>
                                                <span class="caption white--text"><span class="hidden-sm-and-down pr-1">Total</span> Check-Ins</span>
                                            </div>
                                        </v-col>
                                        <v-col cols="6" sm="4" order="2" order-sm="2">
                                            <div class="stat-item">
                                                <span class="title text-md-h4 success--text">{{ getResponseTime() }}</span>
                                                <br>
                                                <span class="caption white--text hidden-sm-and-down">Average Response Time</span>
                                                <span class="caption white--text hidden-md-and-up">Avg. Response Time</span>
                                            </div>
                                        </v-col>
                                        <v-col cols="3" sm="4" order="3" order-sm="3">
                                            <div class="stat-item hover-item" @click="view=1">
                                                <span class="title text-md-h4 error--text">{{ user.total_alerts || '0' }}</span>
                                                <br>
                                                <span class="caption white--text">Total Alerts <span class="hidden-sm-and-down">Sent</span></span>
                                            </div>
                                        </v-col>
                                    </v-row>
                                </div>
                            </v-card-text>
                        </v-card>
                    </v-col>
                    <v-col cols="12">
                        <v-card class="elevation-1 mb-3" :loading="loading">
                            <v-tabs v-model="view" centered slider-color="primary" slider-size="3" color="primary" class="tabs-bold" background-color="#eee">
                                <v-tab @click="$appFn.trackEvent({ action: 'nav_event', options: { action_name: 'Activity: Recent Activity Tab Clicked' }});"><span class="hidden-sm-and-down pr-1">Recent</span> Activity</v-tab>
                                <v-tab @click="$appFn.trackEvent({ action: 'nav_event', options: { action_name: 'Activity: Alerts Tab Clicked' }});"><span class="hidden-sm-and-down pr-1">Your</span> Alerts</v-tab>
                                <v-tab @click="$appFn.trackEvent({ action: 'nav_event', options: { action_name: 'Activity: Shared Notes Tab Clicked' }});">Shared Notes</v-tab>
                            </v-tabs>
                            <v-divider />
                            <v-card-text class="pa-0">
                                <template v-if="loading">
                                    <v-skeleton-loader
                                        v-for="n in 3"
                                        :key="n"
                                        :loading="true"
                                        type="list-item-three-line"
                                        class="mx-auto my-0"
                                    ></v-skeleton-loader>
                                </template>
                                <template v-else>
                                    <template v-if="view == 0">
                                        <Activity :items="items" />
                                    </template>
                                    <template v-if="view==1">
                                        <Alerts :items="alerts" />
                                    </template>
                                    <template v-if="view==2">
                                        <Shares :items="shares" />
                                    </template>
                                </template>
                            </v-card-text>
                        </v-card>
                    </v-col>
                    <v-col cols="12">
                        <v-card color="white">
                            <v-card-text class="info-card">
                                <v-row dense class="align-center">
                                    <v-col cols="12" md="4" lg="3">
                                        <p class="text-center secondary--text title mt-2 mb-0">
                                            <v-icon color="primary" x-large>fa fa-clock</v-icon>
                                            <br>
                                            Your Activity
                                        </p>
                                    </v-col>
                                    <v-col cols="12" md="8" lg="9">
                                        <p class="secondary--text lh-13 text-center text-md-left subtitle-1 mb-1">
                                            <v-icon color="primary" small class="mr-1">fa fa-history</v-icon> Your activity is only retained for 7 days prior to your last check-in.
                                        </p>
                                        <p class="secondary--text lh-13 text-center text-md-left subtitle-1 mb-1">
                                            <v-icon color="primary" small class="mr-1">fa fa-lock</v-icon> Only you can see your activity.
                                        </p>
                                        <p class="secondary--text lh-13 text-center text-md-left subtitle-1 mb-1">
                                            <v-icon color="primary" small class="mr-1">fa fa-info-circle</v-icon> Emergency contacts can view your last check-in time if you fail to check-in.
                                        </p>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
    </div>
</template>
<script>
    
import { mapState } from 'vuex'

export default {
    name: 'UserActivity',
    components: {
        Activity: () => import('@/components/user/activity/Activity'),
        Alerts: () => import('@/components/user/activity/Alerts'),
        LeftNav: () => import('@/components/ui/LeftNav'),
        Shares: () => import('@/components/user/activity/Shares'),
    },
    computed: {
        ...mapState(['user']),
    },
    data: () => ({
        alerts: [],
        items: [],
        shares: [],
        loading: false,
        view: 0,
    }),
    methods: {
        initData(){
            if(this.$route.params.view == 'shares'){
                this.view=2;
            }
            this.getItems();
        },
        getItems(){ 
            this.loading = true;
            let request = {
                endpoint: '/activity', 
                method: 'get',
                apiKey: this.user.api_token || null
            };
            this.$appFn.callApi(request).then((res)=>{
                if(res.status == 200){
                    this.items = res.data;
                }
            }).catch(()=>{
            }).finally(()=>{
                this.loading = false;
            });
        },
        getAlerts(){ 
            if(this.alerts.length){
                return;
            }
            this.loading = true;
            let request = {
                endpoint: '/alerts', 
                method: 'get',
                apiKey: this.user.api_token || null
            };
            this.$appFn.callApi(request).then((res)=>{
                if(res.status == 200){
                    this.alerts = res.data;
                }
            }).catch(()=>{
            }).finally(()=>{
                this.loading = false;
            });
        },
        getShares(){ 
            if(this.shares.length){
                return;
            }
            this.loading = true;
            let request = {
                endpoint: '/notes/share', 
                method: 'get',
                apiKey: this.user.api_token || null
            };
            this.$appFn.callApi(request).then((res)=>{
                if(res.status == 200){
                    this.shares = res.data;
                }
            }).catch(()=>{
            }).finally(()=>{
                this.loading = false;
            });
        },
        getResponseTime(){
            let secs = this.user.avg_response_time;
            let mins = Math.ceil(secs / 60);
            let hours = 0;
            if(mins > 59){
                hours = Math.floor(mins / 60);
                mins = mins - (hours * 60);
                if(hours > 1){
                    return hours + 'hrs ' + mins + 'mins';
                }
                return '1hr ' + mins + 'mins';
            }
            if(mins > 1){
                return '~ ' + mins + ' minutes';
            }
            return '< 1 minute';
        },
    },
    mounted(){
		this.initData();
	},
    watch: {
        view(){
            if(this.view == 1){
                this.getAlerts();
            }
            if(this.view == 2){
                this.getShares();
            }
        }
    }
}
</script>